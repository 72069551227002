<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Pemberian'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-loan-lender')"
        >
          Tambah Pemberian
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-loan-lender')"
        >
          Tambah
        </v-btn>
      </div>

      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="administrations"
        :items-per-page="10"
        :search="searchQuery"
        @click:row="toDetail"
      ></v-data-table>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadMore"
      >
        Load More Data
      </v-btn>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadAll"
      >
        Load All Data
      </v-btn>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: false,
      isAllLoaded: false,
      headers: [
        {
          text: "Jenis Pembayaran",
          align: "start",
          sortable: true,
          value: "nama_jenis_pembayaran",
        },
        { text: "Frekuensi Pembayaran", value: "nama_frekuensi_pembayaran" },
        { text: "Pendanaan", value: "nilai_pendanaan_formatted" },
        { text: "Angsuran", value: "nilai_angsuran_formatted" },
        { text: "Suku Bunga", value: "suku_bunga_pinjaman" },
        { text: "Jangka Waktu Pinjaman", value: "jangka_waktu_pinjaman" },
        { text: "Tanggal Jatuh Tempo", value: "tgl_jatuh_tempo_formatted" },
        { text: "Tanggal Pendanaan", value: "tgl_pendanaan_formatted" },
      ],
      administrations: [],
      administrationsPagination: {
        firstPage: 1,
        lastPage: 100,
      },
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/edit-loan-lender/" + item.id);
    },
    async getAdministration() {
      this.loading = true;

      await store
        .dispatch(
          "office/fetchListAdministration",
          this.administrationsPagination
        )
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.administrations = store.state.office.listAdministration;
      this.administrations.forEach((a) => {
        a.nilai_pendanaan_formatted = converters.currency(a.nilai_pendanaan);
        a.nilai_angsuran_formatted = converters.currency(a.nilai_angsuran);
        a.tgl_jatuh_tempo_formatted = converters.date(a.tgl_jatuh_tempo);
        a.tgl_pendanaan_formatted = converters.date(a.tgl_pendanaan);
      });
    },
    loadMore() {
      if (this.administrationsPagination.lastPage <= 40000) {
        this.administrationsPagination.lastPage += 1000;
        this.getAdministration();
      } else {
        this.isAllLoaded = true;
      }
    },
    loadAll() {
      if (this.administrationsPagination.lastPage <= 40000) {
        this.administrationsPagination.lastPage += 40000;
        this.getAdministration();
        this.isAllLoaded = true;
      }
    },
  },

  mounted() {
    this.getAdministration();
  },
};
</script>
